/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var psoulQuizResult = "";

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        /* ------ Start Helper Functions ------ */

        Array.prototype.remove = function () {
          var what, a = arguments, L = a.length, ax;
          while (L && this.length) {
            what = a[--L];
            while ((ax = this.indexOf(what)) !== -1) {
              this.splice(ax, 1);
            }
          }
          return this;
        };

          function detectTouch() {
              return 'ontouchstart' in window || navigator.maxTouchPoints;
          }


          /* ------ End Helper Functions ------ */

        /* ------ Start Cookiehandler ------ */
        function getCookie(name) {
          var dc = document.cookie;
          var prefix = name + "=";
          var begin = dc.indexOf("; " + prefix);
          if (begin === -1) {
            begin = dc.indexOf(prefix);
            if (begin !== 0) {
              return null;
            }
          } else {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end === -1) {
              end = dc.length;
            }
          }
          return unescape(dc.substring(begin + prefix.length));
        }
        /* ------ End Cookiehandler ------ */

        /* ------ Start Debouncer ------ */
        function debounce(func, wait, immediate) {
          var timeout;
          return function () {
            var context = this, args = arguments;
            var later = function () {
              timeout = null;
              if (!immediate) {
                func.apply(context, args);
              }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
              func.apply(context, args);
            }
          };
        }
        /* ------ End Debouncer ------ */

        /* ------ Start Newsletterform ------ */
        function isEmail(email) {
          var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          return regex.test(email);
        }

        $('#displayform').submit(function () {
          var emailvalue = $('#input_textfield_4').val();
          if ($('#input_textfield_5').val() != "" || $('#input_textfield_6').val() != ""){
            return false;
          };
          if ( emailvalue == '') {
            $('#input_textfield_4').addClass('validateerror');
            $('.message_info').html('Bitte das Pflichtfeld ausfüllen.');
            $('input[type=submit]').blur();
            return false;
          } else if(isEmail(emailvalue) == false){
            $('#input_textfield_4').addClass('validateerror');
            $('.message_info').html('Bitte gültige E-Mail Adresse eintragen.');
            $('input[type=submit]').blur();
            return false;
          }
          //GTM Tracking Google

          var GTM_Data_forms = { "event": "formSubmit", "formName":"Psoul Newsletter Subscription Form"};
          GTM_Data_forms.formStep = "Form Submit";
          GTM_Data_forms.formStepNr = "2";
          GTM_Data_forms.formStepCount = "3";

          publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
          console.log("Form View tracked: " + GTM_Data_forms["formName"]);
          Cookies.set("nlaccepted_psoul", "true", { expires: -3000, path: '/psoul/' });
          return true;
        });
        /* ------ End Newsletterform ------ */


        /* ------ Start Newsletter Subscription Success ------ */
        if ( (typeof Cookies.get('nlsuccess_psoul') !== 'undefined') && (Cookies.get('nlsuccess') === '1')  ) {
          var newslettersuccesscookie = 1;
        }
        if ( (typeof Cookies.get('nlaccepted_psoul') !== 'undefined') && (Cookies.get('nlaccepted') === '1')  ) {
          var newslettersuccesscookie = 1;
        }
        if ( (typeof Cookies.get('nlexpired_psoul') !== 'undefined') && (Cookies.get('nlexpired') === '1')  ) {
          var newslettersuccesscookie = 1;
        }

        if (newslettersuccesscookie === 1) {
          jQuery.smoothScroll({
            offset: -200,
            scrollTarget: '#newsletterform'
          });

          Cookies.remove('nlsuccess', '2');
          Cookies.remove('nlaccepted', '2');
          Cookies.remove('nlexpired', '2');
          //$(document).scrollTop($("#newsletterform").offset().top);
        }

        /* ------ End Newsletter Subscription Success ------ */
        
        /* ------ Start fixed Header ------ */
        function headerSizeAdjust() {
          var scrollheight = $(document).scrollTop();
          if (scrollheight >= 50) {
            $('.header-fixed').css('height', '90');
          }
          else{
            $('.header-fixed').css('height', '120');
          }
          if (window.innerWidth <= 576) {
            if(scrollheight >= 40){
              $('.header-fixed').css('height', '60');
            }
            else{
              $('.header-fixed').css('height', '90');
            }
          }
          
        }
        $(document).on('scroll', headerSizeAdjust);
        $( document ).ready(headerSizeAdjust);

       

        function init_stuff() {
          //articles in container
          //mobile only
          if (window.innerWidth <= 768) {
            var height = window.innerHeight - (jQuery('.menu-row').height() + 97);
            $('#nav-episodes-list').css("height", height);
          }
        }
        $( document ).ready(init_stuff);
        $( window ).resize(init_stuff);

        $(document).on('click', '.header-menu-icon, .overlay-close-icon-x', function () {
          var el = jQuery(".menu-overlay");
          if (el.hasClass("open")) {
            setTimeout(function () {
              el.removeClass("open");
            }, 50);
            el.removeClass("enable-box-shadow");
            setTimeout(function () {
              el.removeClass("animate");
            }, 200);
          }
          else{
            el.addClass("open");
            el.addClass("animate");
            
            setTimeout(function () {
              el.addClass("enable-box-shadow");
            }, 200);
          }
        });
        $(document).click(function (event) {
          var menu = document.querySelector('.menu-overlay');
          if (menu.classList.contains('open')) {
              if (!document.querySelector('.header-menu-icon').contains(event.target) && !menu.contains(event.target)) {
                setTimeout(function () {
                  menu.classList.remove('open');
                }, 50);
                menu.classList.remove('enable-box-shadow');
                setTimeout(function () {
                  menu.classList.remove('animate');
                }, 200);
              }
          }
      });

        /* ------ End fixed Header ------ */

        /* ------ Start Frontpage Header CTA ------ */
        if ($(".js-scrolltonlbox").length) {
          $(document).on('click', '.js-scrolltonlbox', function (e) {
            jQuery.smoothScroll({
              offset: -120,
              scrollTarget: '#newsletterform'
            });
          });
        }
        /* ------ End Frontpage Header CTA ------ */

        $(document).on('click', '.thumbnail-wrapper', function () {
          jQuery(".thumbnail-wrapper").hide();
          jQuery(".videoWrapper__brightcove").fadeIn();
          var myPlayer = videojs.getPlayer($('video-js')[0]);
          myPlayer.play();
          
        });

        /* ------ Start Article Progress Indicator ------ */
        if ($('.reading-progress-container').length && $("body").width() > 768) {
          $('.reading-progress-container').stick_in_parent({
            recalc_every: 30,
            offset_top: 160
          });

          $(document).on('scroll',function () {
            var readingprogressContainerHeightContent = $('.articlecontent').height();
            var readingprogressContainerHeightHeader = $('.article-header').height();
            var readingprogressContainerHeight = readingprogressContainerHeightContent + readingprogressContainerHeightHeader;

            var scrollHeight = $(document).scrollTop();

            var readingprogressPercentage = scrollHeight / (readingprogressContainerHeight / 100);
            if (typeof (readingprogressPercentage) !== 'number' && readingprogressPercentage > 100) {
              readingprogressPercentage = 100;
            }

            if (readingprogressPercentage >= 75) {
              var readingprogressOpacity = (100 - readingprogressPercentage) / 10;
            } else {
              readingprogressOpacity = 1;
            }

            $('.reading-progress-active').css({ height: readingprogressPercentage + '%'});
            $('.reading-progress-container').css({ opacity: readingprogressOpacity});
          });
        }
        /* ------ End Article Progress Indicator ------ */

        /* ------ Start Newsletterform Validation ------ */
        if ($('form#displayform').length) {
          $(document).on('click', 'form#displayform .js-submit', function (e) {
            e.preventDefault();
            $("form#displayform").validate();
            if ($('form#displayform').valid()) {

              $('form#displayform').submit();
            }
          });
        }
        /* ------ End Newsletterform Validation ------ */

        /* ------ Start Article Slider ------ */
        //MAIN-Slider
        if ($('.slidercontainer').length) {
          var mySwiper = new Swiper('.swiper-main-container', {
            loop: true,
            centeredSlides: true,
            initialSlide: 0,

            navigation: {
              nextEl: '.slider-next',
              prevEl: '.slider-prev',
            }
          });

          //NEXT-Slider
          var mySwiperNext = new Swiper('.swiper-next-container', {
            loop: true,
            initialSlide: 1,
            centeredSlides: true,
            allowTouchMove: false,
            effect: 'slide',
            navigation: {
              nextEl: '.slider-next',
              prevEl: '.slider-prev',
            }
          });

          //PREV-Slider
          var mySwiperPrev = new Swiper('.swiper-prev-container', {
            loop: true,
            initialSlide: -1,
            centeredSlides: true,
            allowTouchMove: false,
            navigation: {
              nextEl: '.slider-prev',
              prevEl: '.slider-next',
            }
          });

          //Swipe Main-Slider
          mySwiper.on('slideNextTransitionStart', function () {
            mySwiperNext.slideNext();
            mySwiperPrev.slideNext();
          });

          mySwiper.on('slidePrevTransitionStart', function () {
            mySwiperNext.slidePrev();
            mySwiperPrev.slidePrev();
          });
        }
        /* ------ End Article Slider ------ */


        /* ------ Start Frontpage Slider ------ */

        if ($('.swiper-frontpage').length) {
          var swiperFrontpage = new Swiper('.swiper-frontpage', {
            loop: true,
            centeredSlides: true,
            speed: 3000,
            effect: 'fade',
            fadeEffect: {
            crossFade: true
            },
            autoplay: {
              delay: 3000,
            },


          });
        }

        $(document).on('click', '.btn-start-quiz', function (e) {

          $('#quizoverlay.overlay_container').fadeIn();
          $('#quizoverlay.overlay_container .overlay').addClass('opened');

          $('html').css('overflow', 'hidden');

        });
        $(document).on('click', '.btn-end-quiz', function (e) {

          $("#quiz-form").validate(
            {
              rules:
              {
                frage_1: { required: true },
                frage_2: { required: true },
                frage_3: { required: true },
                frage_4: { required: true },
                frage_5: { required: true },
                frage_6: { required: true }
              },
              messages:
              {
                frage_1:
                {
                  required:"Bitte angeben."
                },
                frage_2:
                {
                  required:"Bitte angeben."
                },
                frage_3:
                {
                  required:"Bitte angeben."
                },
                frage_4:
                {
                  required:"Bitte angeben."
                },
                frage_5:
                {
                  required:"Bitte angeben."
                },
                frage_6:
                {
                  required:"Bitte angeben."
                }
              },
              errorPlacement: function(error, element)
              {
                  if ( element.is(":radio") )
                  {
                      error.appendTo( element.parents('.quiz-question') );
                  }
                  else
                  { // This is the default behavior
                      error.insertAfter( element );
                  }
               }
            });
          if ($("#quiz-form").valid()) {
            var answers = {"a": 0, "e": 0, "f": 0, "l": 0, "s": 0, "x": 0};

            $(".quiz-question .radio:checked").each(function () {
              answers[$(this).val()]++;
            });
            var GTM_Data_forms = { "event": "formSubmit", "formName":"Psoul Schuppenflechte Quiz Form"};
            if (answers.f !== 0) {
              console.log(answers.f + ": Antwort F (Erscheinungsfreie Patienten)");
              GTM_Data_forms.formResult = "Erscheinungsfreie Patienten";
              $(".quiz-headline").html("Du denkst kaum noch an deine Schuppen-flechte? Man sieht (fast) nichts mehr?");
              $(".quiz-pre-content").html("Das ist doch Wahnsinn. Herzlichen Glückwunsch! Hättest du das am Anfang deiner Therapie für möglich gehalten? Sei stolz auf diesen Erfolg und genieße die neu gewonnene Freiheit.");
              $(".quiz-content").html("Damit das auch in der Zukunft so bleibt, ist es natürlich wichtig, dass du die Therapie nicht einfach abbrichst, sondern weiter am Ball bleibst. Denn ganz heilen lässt sich die Schuppenflechte bisher leider nicht. Aber mit einer entsprechenden Therapie ist erscheinungsfreie Haut, wie du siehst, endlich auch langanhaltend möglich. Und das ist doch auch schon <a class='link-tracked-form-link' href='/psoul/ich-bin-so-frei/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>ein ganz neues Lebensgefühl </a>. <br><br>Wenn du möchtest, dann erzähl auch anderen davon, die diesen Weg noch vor sich haben. Zum Beispiel auf dem neuen Portal <a class='link-tracked-form-link' href='/psoul/offen-sprechen-und-anderen-helfen/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>„Haut zeigen – ohne Wenn und Aber“</a>. Denn durch die gegenseitige Unter-stützung werden wir alle stärker.");
            }
            else if (answers.l !== 0) {
              console.log(answers.l + ": Antwort L (Patienten mit leichter Pso)");
              GTM_Data_forms.formResult = "Patienten mit leichter Pso";
              $(".quiz-headline").html("Es scheint, als hättest du nur eine leichte Form der Schuppenflechte, die dich im Alltag nicht allzu sehr einschränkt. Ein Glück!");
              $(".quiz-pre-content").html("Manchmal kann auch eine leichte Schuppenflechte belasten und nervig sein. Sei es im Job, wenn es eben doch immer wieder ein bisschen sichtbar ist oder juckt, oder im Privatleben. Ob und wie stark deine Arbeit, Freundschaften und Beziehungen durch die Schuppenflechte beeinflusst werden, hängt letztendlich auch stark von den betroffenen Stellen und den Möglichkeiten zu deren Behandlung ab.");
              $(".quiz-content").html("Sämtliche Informationen zur Behandlung der Schuppenflechte sowie Tipps und Tricks, um im Alltag bestmöglich damit umzugehen, findest du auf unserer <a class='link-tracked-form-link' class='link-tracked-form-link' href='/erkrankung/schuppenflechte/krankheitsbild/#ueberblick-schuppenflechte' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>AbbVie Care Plattform</a>. <br><br>Wenn du nicht genau weißt, ob eine leichte oder mittelschwere Schuppenflechte hast, dann trau dich und sprich deinen Arzt an. Tipps zum Gespräch mit dem Arzt und dem Setzen von Therapiezielen findest du <a class='link-tracked-form-link' class='link-tracked-form-link' href='/erkrankung/schuppenflechte/behandlungsziel/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a>");
            }
            else if (answers.s > answers.e && answers.s > answers.a || answers.s == answers.e && answers.s > answers.a || answers.s == answers.a && answers.s > answers.e || answers.s == answers.a && answers.s == answers.e) {
              console.log(answers.s + ": Antwort S (Safety-driven Patients)");
              GTM_Data_forms.formResult = "Safety-driven Patients";
              $(".quiz-headline").html("Die gute Nachricht zuerst: Es ist mehr möglich, als du vielleicht glaubst!");
              $(".quiz-pre-content").html("Patienten, Ärzte und Pharmaindustrie befinden sich alle auf einem gemeinsamen Weg und forschen immer weiter an gut verträglichen und gleichzeitig wirkungsvollen Therapien. In den letzten Jahren hat sich viel getan, neue Möglichkeiten sind entstanden und Medikamente noch sicherer geworden. Was heute schon alles möglich ist, kannst du <a class='link-tracked-form-link' href='/psoul/zukunft-ist-heute/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a> nachlesen.");
              $(".quiz-content").html("Wichtig ist, dass du offen mit deinem Arzt über deine Sorgen und Nöte sprichst – aber auch über deine Ziele und was du brauchst, um wieder besser leben zu können. Die Schuppenflechte beeinträchtigt dich am Arbeitsplatz oder im Liebesleben? Dann trau dich und sprich das auch an. Tipps zum Gespräch mit dem Arzt findest du <a class='link-tracked-form-link' href='/erkrankung/schuppenflechte/behandlungsziel/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a>. <br><br> Und denk immer daran: Du bist nicht allein! Wie Sabine es beispielsweise geschafft hat, sich von Sorgen und Ängsten rund um die Schuppenflechte frei zu machen, kannst du  <a class='link-tracked-form-link' href='/psoul/der-frei-schwimmer/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a> nachlesen. Zusammen mit deinem Arzt als Partner kannst auch du das erreichen!");
            }
            else if (answers.e > answers.a && answers.e > answers.s || answers.e == answers.a && answers.e > answers.s) {
              console.log(answers.e + ": Antwort E (Early-engaging Patients)");
              GTM_Data_forms.formResult = "Early-engaging Patients";
              $(".quiz-headline").html("Weiter so! Deine Hartnäckigkeit zahlt sich aus und du bist schon fast am Ziel.");
              $(".quiz-pre-content").html("Deine Haut ist noch nicht ganz erscheinungsfrei? Dann hast du das gute Recht, weiter ungeduldig zu sein. Denn, wer ungeduldig ist, kommt schneller ans Ziel. Warum Ungeduld eine Tugend ist, kannst du auch <a class='link-tracked-form-link' href='/psoul/von-der-macht-der-ungeduld/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a> nachlesen.");
              $(".quiz-content").html("Übrigens: Ärzte diskutieren derzeit über neue Therapieziele in der Pso-Behandlung. Eine (nahezu) erscheinungsfreie Haut mit einem PASI 0 oder < 3 ist schon für viele die neue Norm. Sprich doch mit deinem Arzt, was das für dich bedeuten kann. Hilfreiche Argumente und Tipps, wie du deine Therapieziele gemeinsam mit deinem Arzt festlegen kannst, findest du auch <a class='link-tracked-form-link' href='/erkrankung/schuppenflechte/behandlungsziel/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a>.");
            }
            else if (answers.a > answers.e && answers.a > answers.s) {
              console.log(answers.a + " : Antwort A (Autonomous Patients)");
              GTM_Data_forms.formResult = "Autonomous Patients";
              $(".quiz-headline").html("Du weißt, was du tust und kannst selbstbestimmt entscheiden.");
              $(".quiz-pre-content").html("So einfach macht dir niemand was vor, und das ist gut so. Manchmal kommt der entscheidende Impuls jedoch von außen – ganz unerwartet. Vielleicht ist das hier und jetzt der entscheidende Moment?");
              $(".quiz-content").html("Nur so viel, es gibt tatsächlich etwas Neues! Derzeit sprechen Ärzte über neue Therapieziele, die bisher unerreichbar schienen. (Nahezu) erscheinungsfreie Haut soll das neue Ziel sein. Mehr dazu, findest du  <a class='link-tracked-form-link' href='/psoul/die-magische-3/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a>. <br><br>Schau dir das mal an und wenn du denkst, dass das einen Versuch wert wäre, dann sprich deinen Arzt darauf an! Hilfreiche Tipps zum Gespräch mit dem Arzt und dem Vereinbaren von Therapiezielen kannst du auch <a class='link-tracked-form-link' href='/erkrankung/schuppenflechte/behandlungsziel/' target='_blank' data-form-name='Psoul Schuppenflechte Quiz Form'>hier</a> nachlesen");
            }

            $("#quiz-form").fadeOut();
            $(".quiz-share-row").fadeIn();
            $("#quizoverlay .content").addClass("bigger-space");

            //GTM Form Tracking
            GTM_Data_forms.formStep = "Form Submit";
            GTM_Data_forms.formStepNr = "2";
            GTM_Data_forms.formStepCount = "2";
            publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
            console.log("formSubmit tracked for: " + GTM_Data_forms.formResult);

            psoulQuizResult = GTM_Data_forms.formResult;

            document.querySelector('.overlay-scroll-anchor').scrollIntoView({
              behavior: 'smooth'
            });


            console.log(answers);

          }
        });

        $(document).on('click', '.close-overlay-x', function (e) {

          $('#quizoverlay.overlay_container').fadeOut();
          $('#quizoverlay.overlay_container .overlay').removeClass('opened');
          $('html').css("overflow", "auto");

        });
        /* ------ End Frontpage Slider ------ */

        /* ------ Start Sharing ------ */

        $(document).on('click', '.articleicons-big-ul .bigicon.share', function (e) {
          e.preventDefault();
          if ($('.socialicons').hasClass('active')) {
            $('.articleicons-big-ul .bigicon.share').removeClass('active');
            $('.socialicons').removeClass('active');
          } else {
            $('.articleicons-big-ul .bigicon.share').addClass('active');
            $('.socialicons').addClass('active');
          }
        });

        $(document).on('click', '.articleicons-big-ul.share .bigicon', function (e) {
          $('.articleicons-big-ul.share').removeClass('active');
        });

        /* ------ End Sharing ------ */

        /* ------ Start Like Function ------ */
        function checkiflikecookieexists() {
          var cookie_ilike = Cookies.get('likecookie');

          if (typeof cookie_ilike === 'undefined') {
            var cookiearray = [];
            Cookies.set('likecookie', cookiearray, { path: '/' });
          }
        }

        var viewedPages = [];

        function initViewedPages() {

            if(!$('body').hasClass('single-psoul_cpt')) return false;
            var viewedPagesCookie = Cookies.get('viewedPages');
            if(typeof viewedPagesCookie !== 'undefined') {
                viewedPages = JSON.parse(viewedPagesCookie);
            }
            countViewedPage();
        }

        function countViewedPage() {

            if($.inArray(post_id, viewedPages) == -1 ) {
                viewedPages.push(post_id);
                setPostAttribs(post_id,'views',null,null);
                Cookies.set('viewedPages', viewedPages, {path: '/'});
            } else {
              //console.log('already in view cookie',post_id);
            }

        }

        initViewedPages();


        function checkifalreadyliked() {
          var cookie_ilike = Cookies.get('likecookie');
          var array_ilike = JSON.parse(cookie_ilike);

          // Single Posts
          if (typeof post_id !== 'undefined') {
            var isinarray = jQuery.inArray(post_id, array_ilike);

            if (isinarray !== -1) {
              $('.bigicon.heart').addClass('active');

            }
          }

          // Archives
          $(".article").each(function () {
            var articleid = $(this).attr('data-articleid');
            if ((typeof articleid !== 'undefined') && jQuery.inArray(articleid, array_ilike) !== -1) {
              $(this).find('.heartcounter').addClass('active');
            }
          });
        }

        function managelike() {
          checkiflikecookieexists();
          checkifalreadyliked();
        }

        function managelikecookie(action, post_id) {
          var cookie_ilike = Cookies.get('likecookie');
          var array_ilike = JSON.parse(cookie_ilike);

          if (action === 'add') {
            array_ilike.push(post_id);
          } else if (action === 'remove') {
            array_ilike.splice($.inArray(post_id, array_ilike), 1);
          }

          Cookies.set('likecookie', array_ilike, { path: '/' });
        }

        function managelikecounter(action, post_id) {
          if (action === 'add') {
              setPostAttribs(post_id,'likes','increase',null);
          } else if (action === 'remove') {
              setPostAttribs(post_id,'likes','decrease', null);
          }
        }

        function getSearchResultCount(s,posttypes) {

            $('.searchoverlay .amount-searchresults').addClass('amount-searchresults--hidden');

            $.ajax({
                url: "/wp-json/ac/internal/getSearchResultCount/?params="+encodeURIComponent(JSON.stringify({'s':s, 'posttypes': posttypes })),
                type: "get",
                success: function (data) {

                    $('.searchoverlay .amount-searchresults > .amount-searchresults__count').text(parseInt(data));
                    $('.searchoverlay .amount-searchresults').removeClass('amount-searchresults--hidden');
//                  console.log('found posts: ',data);

                }
            });

        }


        $(document).on('keyup', 'form.searchform input[name="s"]', debounce( function() {

          if($(this).val().length > 0) {
              $('.searchoverlay .amount-searchresults').addClass('amount-searchresults--hidden');
          }

          if ($(this).val().length == 0) {
              $('.searchoverlay .amount-searchresults').removeClass('amount-searchresults--hidden');
          }

          if ($(this).val().length > 3 ) {
              getSearchResultCount($(this).val(),['psoul_cpt']);
              $('[data-binding="searchlink"]').attr('href', '/psoul/?s=' + $(this).val() );
          }

        },500, false ));


        



        function likeclicked(like, post_id) {
          if (like === 1) {
            managelikecookie('add', post_id);
            managelikecounter('add', post_id);
          } else if (like === 0) {
            managelikecookie('remove', post_id);
            managelikecounter('remove', post_id);
          }
        }

        managelike();

        if (typeof post_id !== 'undefined') {
          $(document).on('click', '.bigicon.heart', function (e) {
            e.preventDefault();
            if ($('.bigicon.heart').hasClass('active')) {
              likeclicked(0, post_id);
              $('.bigicon.heart').removeClass('active');
            } else {
              likeclicked(1, post_id);
              $('.bigicon.heart').addClass('active');
            }
          });
        }
        /* ------ End Like Function ------ */

        /* ------ Start Headerfilter ------ */

        if ( ! $( ".header-filter .filter-count" ).length ) {
          $('.show-headerfilter').addClass('active');
        }

        $(document).on('click', '.header-filter .sort-articles', function (e) {
          e.preventDefault();
          $('.show-headerfilter').addClass('active');
          $(this).remove();
        });

        /* ------ End Headerfilter ------ */

        /* ------ Start Header Filter ------ */
        function sortarticles(sortingtype, order) {

          //console.log(sortingtype);
          //console.log(order);

          filters.orderby.param = sortingtype;
          filters.orderby.order =  order;

          //console.log(filters);

            $('.preloader_container').fadeIn();
            $('.articlesection').animate({ opacity: 0 });

            setFilterLink();

            $('#article_ajax').load(filterLink + ' #article_ajax .article_ajax--wrapper', function (response, status, xhr) {
                if (status == "success") {
                    $('.preloader_container').fadeOut();
                    $('.articlesection').animate({opacity: 1});
                }
            });




            //sortingtype == "newest" or "mostliked" or "mostviewed"
          // order == 'asc' or 'desc'
        }

        $(document).on('click', '.header-filter-button', function (e) {
          e.preventDefault();

          function removefilterclasses() {
            $('.header-filter-button').blur().removeClass('active filter-down filter-up');
          }

          if ($(this).hasClass('filter-up')) {
            removefilterclasses();
            sortarticles($(this).attr('data-filter'), 'ASC');
            $(this).addClass('active').removeClass('filter-up').addClass('filter-down');
          } else if ($(this).hasClass('filter-down')) {
              sortarticles('date', 'DESC');
              removefilterclasses();
            $(this).removeClass('filter-down').removeClass('active');
            $('[data-filter="date"]').addClass('active filter-up');
          } else {
            removefilterclasses();
            $(this).addClass('active').addClass('filter-up');
            sortarticles($(this).attr('data-filter'), 'DESC');
          }

        });
        /* ------ End Header Filter ------ */


        /* ------ Start Filterlogic ------ */


        // Data Level 2


        var filters = { 'cats': [], 'tags': [], 'isEmpty': true, 'orderby' : {'param': 'date', 'order' : 'DESC'} };

        var filterLink;

        function createFilterLink(filters) {
          var link;
          return link;
        }

        function add_hoverclass() {
          $(this).addClass('filter_showhover');
          $(this).off('mouseleave', add_hoverclass);
        }

        function buildFilterLink() {
          return encodeURIComponent(JSON.stringify(filters));
        }



        function closeOverlays() {

          $('.fullscreenoverlay').removeClass('overlay-active');
          setTimeout(function() { $('.overlay_container').fadeOut('fast'); }, 200);
          $('.overlay').removeClass('opened');
          $('html').css('overflow', 'visible');

        }

        function getFilterPostCount() {


            $('.filteroverlay .amount-searchresults').addClass('amount-searchresults--hidden');

          $.ajax({
            url: "/wp-json/ac/internal/getPsoulPostCount/?filter="+buildFilterLink(),
            type: "get",
            success: function (data) {

                $('.filteroverlay .amount-searchresults > .amount-searchresults__count').text(parseInt(data));
                $('.filteroverlay .amount-searchresults').removeClass('amount-searchresults--hidden');
            }
          });
        }


        function setFilterLink() {

          filters.isEmpty = (filters.cats.length == 0 && filters.cats.length == 0);
          filterLink = '/psoul/?filter=' + buildFilterLink();
          $('[data-binding="filterlink"]').attr('href', filterLink);

        }


        function markParent(el) {

          var parent = el.parents('[data-filter-col]');
          var parentLink = parent.find('a[data-level="1"]');
          var hasActiveLevel2items = (parent.find('a[data-level="2"][data-active="true"]').length ? true : false);
          var hasInactiveLevel2items = (parent.find('a[data-level="2"][data-active="false"]').length ? true : false);

          parentLink.attr('data-all-active', (!hasInactiveLevel2items && hasActiveLevel2items ? 'true' : 'false') );


          switch (hasActiveLevel2items) {
            case true:
              parentLink.attr('data-active', 'true');
              //parentLink.addClass('filter_showhover');
              break;
            case false:
              parentLink.attr('data-active', 'false');
              parentLink.removeClass('filter_showhover');
              break;
          }

          if (!parent.find('a[data-level="2"][data-active="false"]').length) {
            parentLink.addClass('filter_showhover');
          }

        }

          function initFilters() {

              if (typeof inherited_filters !== 'undefined') {

                  inherited_filters.cats.map(function (value) {
                      var cat = $('[data-type="cats"][data-level="2"][data-value="' + value + '"]');
                      cat.attr('data-active', 'true');
                      markParent(cat);
                  });



                  $('[data-type="cats"][data-active="true"]').addClass('filter_showhover');

                  filters = inherited_filters;


                  // set order type
                  if($('[data-filter="'+filters.orderby.param+'"]').length) $('[data-filter]').removeClass('active filter-up filter-down');
                  $('[data-filter="'+filters.orderby.param+'"]').addClass('active filter-'+ (filters.orderby.param == 'DESC' ? 'down' : 'up'));


                  if ($('body').hasClass('single-psoul_cpt')) {
                      setTimeout(function () {
                        getFilterPostCount();
                      }, 1000);
                  }



                  setFilterLink();

              }

          }

          
        $(document).on('click', '[data-binding="filterlink"]', function (event) {

          $('.preloader_container').fadeIn();
          $('.filteroverlay').removeClass('overlay-active');
          $('html').css('overflow', 'visible');
          $('.articlesection').animate({ opacity: 0 });

            //console.log($('body').attr('class'));

            if($('body').attr('class').match(/single-psoul_cpt|search-results/) || filters.isEmpty) {

              window.location.href= (filters.isEmpty ? '/psoul/' : filterLink);

            } else {

                $('#ajax_wrapper').load(filterLink + ' #ajax_wrapper .ajax_wrapper__innerwrapper', function (response, status, xhr) {
                    if (status == "success") {

                      jQuery.smoothScroll({
                          offset: (($('#header .headerbar').height() + 35) * -1),
                          scrollTarget: '#ajax_wrapper'
                      });

                      if (!$(document).find('#frontpage')) {
                        $('.header-fixed-placeholder').addClass('active');
                      }

                      $('.preloader_container').fadeOut();
                      $('.articlesection').animate({opacity: 1});

                      if(filters.isEmpty === true) $('.show-headerfilter').addClass('active');

                    }
                 });

            }

          event.preventDefault();

        });

        $('a[data-level="2"]').each(function (index, el) {

          $(el).on('click', function () {

            switch ($(this).attr('data-active')) {

              case 'true':
                filters[$(this).attr('data-type')].remove($(this).attr('data-value'));
                $(this).attr('data-active', 'false').removeClass('filter_showhover');
                $(this).off('mouseleave', add_hoverclass);
                break;

              case 'false':
                filters[$(this).attr('data-type')].push($(this).attr('data-value'));
                $(this).on('mouseleave', add_hoverclass);
                $(this).attr('data-active', 'true');
                break;
            }

            markParent($(this));


              getFilterPostCount();

              setFilterLink();


            return false;

          });

        });


        // Datalevel 1

        $('a[data-level="1"]').each(function (index, el) {


          $(el).on('click', function (event) {

            var datalevel1link = $(this);
            datalevel1link.attr('data-active', ($(this).next('ul').find('a[data-active="false"]').length ? 'false' : 'true'));

            var ct = 0;

            switch ($(this).attr('data-active')) {
              case 'false':

                datalevel1link.attr('data-all-active', 'true');

                $(this).attr('data-active', 'true');
                $(this).on('mouseleave', add_hoverclass);

                $(this).next('ul').find('a[data-active="false"]').each(function () {

                  var delay = 30 * ct;
                  var obj = $(this);
                  filters[obj.attr('data-type')].push(obj.attr('data-value'));


                  setTimeout(function () {
                    obj.attr('data-active', 'true').addClass('filter_showhover');
                  }, delay);
                  ct++;


                });


                break;


              case 'true':

                datalevel1link.attr('data-all-active', 'false');

                $(this).attr('data-active', 'false');
                $(this).off('mouseleave', add_hoverclass);
                $(this).removeClass('filter_showhover');

                $(this).next('ul').find('a[data-active]').each(function () {

                  var delay = 30 * ct;
                  var obj = $(this);

                  filters[obj.attr('data-type')].remove(obj.attr('data-value'));

                  setTimeout(function () {
                    obj.attr('data-active', 'false').removeClass('filter_showhover');
                  }, delay);
                  ct++;

                });


                break;

            }

            setFilterLink();
            getFilterPostCount();

            event.preventDefault();






          });





        });


        /* ------ End Filterlogic ------ */




          /* ------ INIT  ------ */



          initFilters();
          if(typeof searchresultcount !== 'undefined') $('.filter-count span').text(searchresultcount);

          $(document).keyup(function (e) { if (e.keyCode === 27) { closeOverlays(); }});
          if(detectTouch()) $('body').addClass('hastouch');



          /* ------ End INIT  ------ */






      },
      finalize: function () {


        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function navigation_slider() {
    var value =$(this).val();
    if ($(this).hasClass('front-page')) {
      $url =  "/psoul/ausgabe-" + value + "/";
    }
    else{
      $url = "/psoul/" + slider_mapper[parseInt(value)] + "/";
    }
    window.location.href = $url;
  }

  $(".episodes-slider").mouseup(navigation_slider);
  $(".episodes-slider").on("touchend", navigation_slider);
  $(".episodes-slider").keyup(navigation_slider);

})(jQuery); // Fully reference jQuery after this point.


//slider episodes text
const
  range = document.getElementById('range'),
  rangeV = document.getElementById('rangeV'),

  setValue = function(){
    if ($('.episodes-slider').hasClass('front-page')) {
      const
      newValue = Number( (range.value - range.min) * 100 / (range.max - range.min) ),
      newPosition = 42 - (newValue * 0.84);
      rangeV.innerHTML = '<span>'+ range.value + ' von '+ range.max+ '</span>';
      rangeV.style.left = 'calc('+ (100-newValue) + '% - ('+newPosition+'px))';
    }
    else{
      const
      newValue = Number( (range.value - range.min) * 100 / (range.max - range.min) ),
      newPosition = 42 - (newValue * 0.84);
      rangeV.innerHTML = '<span>'+ range.value + ' von '+ range.max+ '</span>';
      rangeV.style.left = 'calc('+ (newValue) + '% + ('+newPosition+'px))';
    }
  };
document.addEventListener("DOMContentLoaded", setValue);
range.addEventListener('input', setValue);

